import router from "@/router";

const user = {
    state: {
        isLoggedIn: !!sessionStorage.getItem('access_token'),
        user: {},
        bonus: null,
        ranking: null,
        payoutsHistory: null,
        checkAgreements: null
    },
    mutations: {
        setLoggedIn: function (state, payload) {
            state.isLoggedIn = payload;
        },
        userUpdate: function (state, payload) {
            state.user = {...state.user, ...payload};
        },
        bonusUpdate: function (state, payload) {
            state.bonus = payload;
        },
        rankingUpdate: function (state, payload) {
            state.ranking = payload;
        },
        payoutsUpdate: function (state, payload) {
            state.payoutsHistory = payload;
        },
        agreementsUpdate: function (state, payload) {
            state.checkAgreements = payload;
        }
    },
    actions: {
        login: function (context, payload) {
            return new Promise((resolve, reject) => {
                window.api.post('/login', payload)
                    .then((response) => {
                        sessionStorage.setItem('access_token', response.data.data.token);
                        context.commit('setLoggedIn', true);
                        context.dispatch('getUser');
                        router.go(history && history.length > 2 ? -1 : {name: 'home'});
                        resolve(response);
                    })
                    .catch((e) => {
                        if (e.response.status === 401) {
                            if (sessionStorage.getItem('access_token')) {
                                sessionStorage.removeItem('access_token')
                            }
                        }
                        reject(e);
                    })
            })
        },
        getAgreements: async function (context) {
            await window.api.get('my-account/check-agreements')
                .then(response => {
                    context.commit('agreementsUpdate', response.data.data.result);
                    // if (response.data.data.result === 1) {
                    //     sessionStorage.setItem('agreements_accepted', 'true');
                    // }
                })
                .catch(() => {})
        },
        logout: function () {
            sessionStorage.removeItem('access_token');
            delete window.api.defaults.headers.common['Authorization'];
            router.go({name: 'login'});
        },
        getUser: async function (context) {
            await window.api.get('my-account/basic-user-data')
                .then(response => {
                    context.commit('userUpdate', response.data.data);
                })
                .catch(() => {})
        },
        getUserFull: async function (context) {
            await window.api.get('my-account/all-user-data')
                .then(response => {
                    context.commit('userUpdate', response.data.data);
                })
                .catch(() => {})
        },
        getBonusSummary: function (context) {
            window.api.get('my-account/bonus-summary')
                .then(response => {
                    context.commit('bonusUpdate', response.data.data || {})
                })
                .catch(() => {
                    context.commit('bonusUpdate', {})
                })
        },
        getRanking: function (context) {
            window.api.get('my-account/ranking')
                .then(response => {
                    context.commit('rankingUpdate', response.data.data[3] || {})
                })
                .catch(() => {
                    context.commit('rankingUpdate', {})
                })
        },
        getBonusPayoutsHistory: function (context) {
            window.api.get('my-bonuses/bonus-payout-history')
                .then(response => {
                    context.commit('payoutsUpdate', response.data.data || [])
                })
                .catch(() => {
                    context.commit('payoutsUpdate', [])
                })
        },
        getPrepaidCard: function (context) {
            window.api.get('my-bonuses/prepaid-card')
                .then(response => {
                    context.commit('userUpdate', response.data.data);
                })
                .catch(() => {})
        }
    },
    getters: {
        isLoggedIn: function (state) {
            return !!state.isLoggedIn;
        },
        user: function (state) {
            return state.user;
        },
        bonus: function (state) {
            return state.bonus;
        },
        ranking: function (state) {
            return state.ranking;
        },
        payoutsHistory: function (state) {
            return state.payoutsHistory;
        },
        checkAgreements: function (state) {
            return state.checkAgreements;
        }
    }
}

export default user;
